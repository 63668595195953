import React, { ComponentType, FC, useState } from 'react';
import Modal from 'react-modal';
import { useTranslations } from '../../../common/hooks/useTranslations';
import { FormModal } from '../../../contentful/content-types/form-modal';
import { CFXExit } from '../../common/CFIcons/CFXExit';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { Div, Button, Col, Container, Row } from '../Elements';
import { OnSuccessMessage, SuccessElementProps } from './marketo-success-message';
import { MarketoFormStyled } from './marketo-styled-form';
import { ButtonCTA } from '../buttons/Button';

export interface MarketoFormModalProps {
  modal: FormModal;
  open: boolean;
  onSuccessBehavior?: 'close' | 'show-message';
  SuccessElement?: ComponentType<{ ctaAction: SuccessElementProps['ctaAction'] }>;
  setOpen: (val: boolean) => void;
}

export const MarketoFormModal: FC<MarketoFormModalProps> = ({
  open,
  setOpen,
  modal,
  SuccessElement: CustomSuccessElement,
  onSuccessBehavior = 'close',
}) => {
  const contentLabel = modal.regularCopy || '';
  const staticStrings = useStaticStrings();
  const defaultButtonText = staticStrings.json_values['Close_window'];
  const translate = useTranslations();

  const SuccessElement = () =>
    CustomSuccessElement ? (
      <CustomSuccessElement ctaAction={() => setOpen(false)} />
    ) : modal.marketoForm ? (
      <>
        <OnSuccessMessage form={modal.marketoForm} ctaAction={() => setOpen(false)} />
        <ButtonCTA
          buttonColor="blue-outline"
          buttonStyle="narrow"
          color="blue"
          onClick={() => setOpen(false)}
        >
          {translate(defaultButtonText)}
        </ButtonCTA>
      </>
    ) : null;

  return (
    <>
      <Modal
        closeTimeoutMS={200}
        isOpen={open}
        className="bg-br-lightBlue h-auto min-h-100 outline-0"
        overlayClassName="fixed top-0 bottom-0 left-0 right-0 bg--grayOpaque z-max overflow-y-scroll br-refresh"
        contentLabel={contentLabel}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={() => setOpen(false)}
        ariaHideApp={true}
      >
        <Div paddingVertical={[2, 6]}>
          <Container>
            <Div marginBottom={[2, 6]}>
              <Row>
                <Col xs={12} display="flex" justifyContent="end">
                  <Button
                    fontSize={6}
                    padding={1}
                    lineHeight={2}
                    className="pointer bn bg--transparent"
                    onClick={() => setOpen(false)}
                  >
                    <CFXExit size={32} color="black" />
                  </Button>
                </Col>
              </Row>
            </Div>
            <Row>
              <Col marginBottom={[4, 8]} lg={{ span: 5, offset: 4 }}>
                {modal.marketoForm && (
                  <MarketoFormStyled
                    SuccessElement={onSuccessBehavior === 'close' ? null : SuccessElement}
                    marketoData={modal.marketoForm}
                    title={modal.title}
                    subtitle={modal.regularCopy}
                    // @TODO: Revisit this behavior
                    onSuccess={() => null}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Div>
      </Modal>
    </>
  );
};
