export type SHA_Algorithm = 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512';

/**
 * Takes a string, hashses it with the specified algorithm, and digests as a hex
 *
 * @param str - String to hash
 * @param alg - Algorithm to use
 * @returns
 */
export const sha256ToHex = async (str: string, alg: SHA_Algorithm = 'SHA-256') => {
  try {
    const msgUint8 = new TextEncoder().encode(str); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest(alg, msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    return hashHex;
  } catch (e) {
    return '';
  }
};
