import { getCookie, getSparrowUserId } from '../../../common/analytics/sparrow';
import { getGAtrackingField, getOptimizeExperiments } from '../../../common/analytics/trackers';
import { useLocale } from '../../../common/hooks/useLocale';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { isUTMParam, UTMParams } from '../../../common/hooks/useUTMParams';
import { isEULocale } from '../../../common/util/locales';
import { MaybeRedwood } from '../../../config';
import { MarketoFormField } from './get-form-data';

const formIdsDoNoTRequirePrivacyPolicy: number[] = [1639, 1649];

export const useMarketoPrivacyPolicy = (formId: number, partnerPrivacyPolicyName?: string) => {
  const staticStrings = useStaticStrings();
  const locale = useLocale();
  const cloudflare_partner_privacy_policy = staticStrings.json_values.form_privacy_partner;
  const default_privacy_policy = staticStrings.json_values.form_privacy;
  const gdpr_privacy_policy = staticStrings.json_values.form_privacy_gdpr;
  if (!formIdsDoNoTRequirePrivacyPolicy.includes(formId)) {
    if (isEULocale(locale)) return gdpr_privacy_policy;
    if (partnerPrivacyPolicyName) return cloudflare_partner_privacy_policy;
    return default_privacy_policy;
  }
  return '';
};

export const swapFields = (
  fields: MarketoFormField[],
  valueKey: keyof MarketoFormField,
  field1: string,
  field2: string
): MarketoFormField[] => {
  const f1 = fields.findIndex((f) => f[valueKey] === field1);
  const f2 = fields.findIndex((f) => f[valueKey] === field2);
  const temp = fields[f1];
  fields[f1] = fields[f2];
  fields[f2] = temp;
  return fields;
};

export function getGoogleData() {
  const experiments = getOptimizeExperiments();
  const userId = getSparrowUserId();
  const gclid = getCookie('gclid');

  let data: any = {
    GOX__c: experiments,
    GACLIENTID__c: getGAtrackingField('clientId'),
    GATRACKID__c: getGAtrackingField('trackingId'),
  };

  if (userId) {
    data['GAUSERID__c'] = userId;
  }

  if (gclid) {
    data['GCLID__c'], gclid;
  }
  return data;
}

export const getTraceResults = () => {
  const redwood = (window as MaybeRedwood).redwood;

  if (!redwood) return;

  const data: any = {};
  if (typeof redwood.colo === 'string') {
    data.CloudFlare_POP__c = redwood.colo;
  }

  if (typeof redwood.country === 'string') {
    data.CountryCode__c = redwood.country;
  }
  return data;
};

export const getUtmData = (utmParams: UTMParams) => {
  let data: any = {};
  for (let param in utmParams) {
    if (isUTMParam(param)) {
      const value = utmParams[param];

      if (value) {
        data[param] = value;
      }
    }
  }
  return data;
};
