const BLOCKED_COUNTRIES = ['IR', 'KP', 'CU', 'SY'];

// This list comes from the values set in the Marketo form
export const BLOCKED_COUNTRIES_LONG = [
  "Korea, Democratic People's Republic of",
  'Cuba',
  'Iran, Islamic Republic of',
  'Syrian Arab Republic',
];

export const userInBlockedCountry = (country: string = '') => {
  return (
    BLOCKED_COUNTRIES.find((code) => code === country) ||
    BLOCKED_COUNTRIES_LONG.find((code) => code === country)
  );
};
